<template>
  <group-form
    ref="form"
    :columnMode="true"
    :group="draft"
    formAction="/admin/venue-groups"
    @submitSuccess="onSubmitSuccess"
    @removeImage="onRemoveImage"
    @currencySelected="enableNextStep()"
  />
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import GroupForm from '@/views/common/venue-groups/Form.vue';

export default {
  mixins: [wizardListenerMixin],
  components: {
    GroupForm,
  },
  computed: {
    ...mapState('entities/groups', ['draft']),
  },
  methods: {
    ...mapMutations('entities/groups', ['UPDATE_DRAFT']),
    onNextStep() {
      this.$refs.form.submit();
    },
    onSubmitSuccess({ data }) {
      this.UPDATE_DRAFT(data.data);
      this.$emit('stepCompleted');
    },
    onRemoveImage() {
      this.UPDATE_DRAFT({ logo: null });
    },
  },
};
</script>

<style scoped>

</style>
