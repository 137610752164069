<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzAutocomplete from '@/components/ui/Autocomplete/EzAutocomplete';
import EzPremiumBadge from '@/components/ui/PremiumBadge/PremiumBadge';
import { EzSelectedDistributor } from '@/components/ui/SelectedItem';
import dayjs from 'dayjs';
import { wizardListenerMixin } from '@/mixins/wizard';
import SupplierForm from '@/views/admin/venues/pending-updates/new-supplier/SupplierForm';
import EzButton from '@/components/ui/Button/EzButton';
import ZoomSlider from '@/views/admin/venues/pending-updates/new-supplier/ZoomSlider';
import VDatePicker from '@/components/v3/patterns/VDatePicker';

/**
*
* @version 1.0.0
* @since 3.0.0
*/
export default {
  mixins: [wizardListenerMixin],
  components: {
    EmptyState,
    EzAutocomplete,
    EzPremiumBadge,
    EzSelectedDistributor,
    SupplierForm,
    EzButton,
    ZoomSlider,
    VDatePicker,
  },
  data() {
    return {
      singlePending: {},
      selectedSupplier: null,
      selectedDate: null,
      tomorrow: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      todayDate: dayjs().format('YYYY-MM-DD'),
      isCreateSupplier: false,
      name: '',
      formValid: false,
    };
  },
  computed: {
    ...mapState('entities/venues', ['offlineOrderNewSupplier']),
    venueId() {
      return this.$route.params.venueId;
    },
    hasSinglePending() {
      return Object.keys(this.singlePending).length;
    },
    hasSupplierInState() {
      return Object.keys(this.offlineOrderNewSupplier.supplier).length;
    },
    isPremiumBanner() {
      return this.selectedSupplier
        && (!this.selectedSupplier.isConnected && this.selectedSupplier.accountType === 'premium');
    },
    hasBothSelected() {
      return this.selectedSupplier && this.selectedDate;
    },
    isNewAddedSupplier() {
      return this.offlineOrderNewSupplier.supplier.address
        && !!Object.keys(this.offlineOrderNewSupplier.supplier.address).length;
    },
  },
  watch: {
    hasBothSelected(val) {
      if (val) this.enableNextStep();
      else this.disableNextStep();
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'adminGetSinglePendingUpdate',
      'venueAddSupplier',
      'venueAssignDistributorOnPendingInvoice',
      'venueFetchSupplierSingle',
    ]),
    ...mapMutations('entities/venues', [
      'SET_OFFLINE_ORDER_NEW_SUPPLIER',
      'SET_PENDING_UPDATE',
      'CLEAR_SELECTED_SUPPLIER',
    ]),
    ...mapActions('entities/venues', ['connectVenueWithDistributor']),
    async onSupplierSelect(supplier) {
      if (supplier.first) {
        this.isCreateSupplier = true;
        this.name = supplier.query;
      } else {
        this.selectedSupplier = supplier.selected;
        this.SET_PENDING_UPDATE({
          pendingUpdate: {
            ...this.singlePending,
            distributor: this.selectedSupplier,
          },
        });
      }
    },
    updateDate(val) {
      this.selectedDate = val;
    },
    removeSelectedSupplier() {
      this.selectedSupplier = null;
      this.selectedDate = null;
      this.CLEAR_SELECTED_SUPPLIER();
      this.singlePending.distributor = null;
      this.SET_PENDING_UPDATE({
        pendingUpdate: this.singlePending,
      });
    },
    editSupplier() {
      this.isCreateSupplier = true;
    },
    submitSuccess({ data }) {
      this.selectedSupplier = data.data;
      this.setOfflineOrderInState();
      this.onFormClose();
    },
    setOfflineOrderInState() {
      this.SET_OFFLINE_ORDER_NEW_SUPPLIER({
        supplier: this.selectedSupplier,
        date: this.selectedDate,
      });
    },
    onNextStep() {
      if (this.selectedSupplier.isConnected) {
        this.setOfflineOrderInState();
        this.$emit('stepCompleted');
        return;
      }
      this.connectVenueWithDistributor({
        venueId: this.venueId,
        distributorId: this.selectedSupplier.id,
      });
      this.setOfflineOrderInState();
      this.$emit('stepCompleted');
    },
    onFormClose() {
      this.isCreateSupplier = false;
    },
    submitSupplierForm() {
      this.$refs.supplierForm.$refs.distributorForm.onSubmit();
    },
  },
  async mounted() {
    const { data } = await this.adminGetSinglePendingUpdate({
      venueId: this.$route.params.venueId,
      pendingId: this.$route.params.pendingId,
    });
    this.singlePending = data.data;
    this.SET_PENDING_UPDATE({
      pendingUpdate: this.singlePending,
    });
    if (this.singlePending.distributor.id !== null) {
      this.selectedSupplier = this.singlePending.distributor;
    }
    if (this.hasSupplierInState) {
      this.selectedSupplier = this.offlineOrderNewSupplier.supplier;
      this.selectedDate = this.offlineOrderNewSupplier.date;
    }
  },
};
</script>
<template>
  <div class="supplier" v-if="hasSinglePending">
    <div class="supplier__box">
      <zoom-slider v-if="singlePending" :data="singlePending.invoices" />
    </div>
    <div :class="['supplier__box', {'new-supplier': isCreateSupplier}]">
      <div v-if="!isCreateSupplier">
        <ez-autocomplete
          v-if="!selectedSupplier"
          ref="autocomplete"
          :placeholder="`Enter ${$t('global.distributor')} Name`"
          :source="`/admin/search/distributors`"
          :hasConnectedParam="false"
          :requestParams="{ venueId: venueId, sameCurrency: 'true' }"
          searchProperty="name"
          :label="`Search for the ${$t('global.distributor')}`"
          @selected="onSupplierSelect"
        >
          <template #firstResult="{ input }">
            <li>
              <div class="create-new-supplier">
                <div class="badge mr-8">
                  <font-awesome-icon icon="plus" transform="shrink-6" />
                </div>
                <span>Create New "{{ input }}"
                  {{ $t('global.distributor') }}</span>
              </div>
            </li>
          </template>
          <template #result="{result}">
            <div class="venue-result">
              <img :src="result.logo" alt="" class="venue-result__image">
              <div class="venue-result__info">
                <div class="venue-result__title">
                  <span :title="result.name">{{ result.name }}</span>
                  <ez-premium-badge v-if="$helpers.isPremium(result.accountType)" />
                </div>
              </div>
            </div>
          </template>
        </ez-autocomplete>
        <ez-selected-distributor
          :hasEditButton="isNewAddedSupplier"
          @remove="removeSelectedSupplier"
          @edit="editSupplier"
          :distributor="selectedSupplier">
          <template #emptyState>
            <empty-state class="empty-state-suppliers">
              <template #badge><img src="@/assets/no-distributor-state.svg" alt=""></template>
              <template #title>Search for the supplier</template>
              <template #info>Check if the supplier already exists on the platform or add a
                new one.</template>
            </empty-state>
          </template>
        </ez-selected-distributor>
        <hr v-if="selectedSupplier">
        <v-date-picker
          v-if="selectedSupplier"
          class="date-picker"
          name="interval"
          label="Order Date"
          v-model="selectedDate"
          formKey="date"
          @dateChange="updateDate"
          :max="todayDate"
        />
      </div>
      <div class="supplier-wrapper" v-else>
        <div class="title">
          {{ isNewAddedSupplier ? `Edit ${$t('global.distributor')} info`
          : `Create new ${$t('global.distributor')}` }}
          <button class="remove-item" @click="onFormClose">
            <font-awesome-icon icon="times"/>
          </button>
        </div>
        <div class="content">
          <SupplierForm
            :supplier="offlineOrderNewSupplier.supplier"
            @submitSuccess="submitSuccess"
            ref="supplierForm"
            @formValid="(val) => formValid = val"
            :name="name"/>
        </div>
        <div class="footer">
          <ez-button
            @click="onFormClose"
            type="link"
            formType="button">Cancel</ez-button>
          <ez-button
            @click="submitSupplierForm"
            :disabled="!formValid">
            {{ isNewAddedSupplier ? 'Save Changes' : `Create ${$t('global.distributor')}` }}
          </ez-button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$height: 708px;
.supplier {
  display: flex;
  &__box {
    flex: 1 1 100%;
    border: 1px #CCD2E0;
    border-radius: 3px;
    max-width: 576px;
    height: $height;

    &:first-child {
      margin-right: 48px;
      border-style: solid;
    }
    &:last-child {
      border-style: dashed;
      padding: 24px;
    }

    .venue-result {
      @extend %flex-center;

      &__image {
        @include size(32px);
        border-radius: 50%;
        border: 1px solid #DEE1E4;
      }

      &__info {
        margin-left: 8px;
      }

      &__titele {
        color: #252631;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }

      &__label {
        color: #6C7995;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
      }
    }

    :deep() .empty-state-suppliers {
      margin-top: 150px;
    }
  }
  .create-new-supplier {
    display: flex;
    align-items: center;
    .badge {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      @extend %flex-center;
      justify-content: center;
      background-color: #E2EAFF;
      color: #4D7CFE;
      @include font-size(20px);
    }
  }
  .new-supplier {
    border-style: solid;
    padding: 0;
  }
  .supplier-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .title, .footer {
      width: 100%;
      padding: 0 24px;
      display: flex;
      align-items: center;
    }
    .title {
      height: 72px;
      justify-content: space-between;
      @include font-size(18px, 24px);
      font-weight: 500;

      .remove-item {
        @extend %button-reset;
        outline: none;
        svg {
          color: #C5CAD5;
        }
        :hover {
          cursor: pointer;
        }
      }
    }
    .footer {
      height: 68px;
      justify-content: flex-end;
    }
    .content {
      max-height: 568px;
      overflow: auto;
      padding: 0 24px;
    }
  }
  .info-message-wrapper {
    margin-bottom: px-to-rem(16px);
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    .info {
      padding: 12px;
      background-color: $yellow-bg;
      border-radius: 3px;
      display: flex;
      width: 100%;
      @include font-size(14px);
      .column-left {
        margin-right: 10px;
        color: $color-primary-orange;
      }
      .column-right {
        color: $color-gray-6C;
        &--title {
          color: $color-gray-25;
          margin-bottom: 8px;
          font-weight: 500;
        }
        .create-invoice {
          padding: 0;
          height: 0;
        }
      }
    }
  }
}
.date-picker {
  :deep() .ez-simple-dropdown__display-container {
    width: 100%;
    .placeholder {
      width: 100%;
    }
  }
}
</style>
