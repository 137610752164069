import { render, staticRenderFns } from "./OrderHistory.vue?vue&type=template&id=1d513270&scoped=true&"
import script from "./OrderHistory.vue?vue&type=script&lang=js&"
export * from "./OrderHistory.vue?vue&type=script&lang=js&"
import style0 from "./OrderHistory.vue?vue&type=style&index=0&id=1d513270&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d513270",
  null
  
)

export default component.exports