<template>
  <forgot-password action="/admin/reset-password">
    <template #backToLogin>
      <router-link :to="{ name: 'admin-login' }">
        <font-awesome-icon icon="arrow-left"></font-awesome-icon>
        <span class="back-to-login">Back to Sign In</span>
      </router-link>
    </template>
  </forgot-password>
</template>

<script>
import { ForgotPassword } from '@/views/common/auth';

export default {
  components: {
    ForgotPassword,
  },
};
</script>
<style scoped lang="scss">
  :deep() .auth__form a span {
    margin-left: .5rem;
  }
</style>
