<script>
/**
 *
 * @version 1.0.0
 * @since 3.0.0
 */

import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button/EzButton';
import EzWizard from '@/components/layout/Wizard';
import steps, {
  OFFLINE_ORDER_STEP_1, OFFLINE_ORDER_STEP_2, OFFLINE_ORDER_STEP_3,
} from '@/views/admin/venues/pending-updates/new-supplier/steps';
import { mapMutations } from 'vuex';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
  },
  data() {
    return {
      OFFLINE_ORDER_STEP_1,
      OFFLINE_ORDER_STEP_2,
      OFFLINE_ORDER_STEP_3,
      currentStep: 0,
      nextDisabled: true,
    };
  },
  computed: {
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === OFFLINE_ORDER_STEP_1;
    },
    isLastStep() {
      return this.currentStep === OFFLINE_ORDER_STEP_3;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    ...mapMutations('entities/venues', ['CLEAR_OFFLINE_ORDER_NEW_SUPPLIER']),
    onWizardExit() {
      this.CLEAR_OFFLINE_ORDER_NEW_SUPPLIER();
      this.$router.push({ name: 'admin-venue-pending-updates' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= OFFLINE_ORDER_STEP_1 && step <= OFFLINE_ORDER_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= OFFLINE_ORDER_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= OFFLINE_ORDER_STEP_3) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
};
</script>
<template>
  <div>
    <ez-wizard
      :class="{'container-wider': currentStep === OFFLINE_ORDER_STEP_1
        || currentStep === OFFLINE_ORDER_STEP_2}"
      :fullWidth="currentStep === OFFLINE_ORDER_STEP_2 || currentStep === OFFLINE_ORDER_STEP_1"
      @exit="onWizardExit"
      @back="goToPreviousStep">
      <template #title>Pending Update</template>
      <template #nav>
        <ul>
          <li :class="{
          'ez-step-active': currentStep === OFFLINE_ORDER_STEP_1,
          'ez-step-completed': currentStep > OFFLINE_ORDER_STEP_1,
        }">
            1. {{ $t('global.distributor') }}
          </li>
          <li :class="{
          'ez-step-active': currentStep === OFFLINE_ORDER_STEP_2,
          'ez-step-completed': currentStep > OFFLINE_ORDER_STEP_2,
        }">
            2. {{ $t('global.products') }}
          </li>
        </ul>
      </template>
      <template #actions>
        <ez-button
          :disabled="nextDisabled"
          @click="goToNextStep">{{ nextCta }}</ez-button>
      </template>
      <template #prevStep>
        <ez-button
          v-if="currentStep > OFFLINE_ORDER_STEP_1 && currentStep < OFFLINE_ORDER_STEP_3"
          type="link"
          formType="button"
          @click="goToPreviousStep">
          <font-awesome-icon icon="arrow-left"/>
          <span>Back</span>
        </ez-button>
      </template>
      <template #pageTitle>{{ currentStepPageTitle }}</template>
      <template #pageInfo>
        <span v-html="currentStepPageInfo"/>
      </template>
      <component
        :is="currentStepComponent"
        @stepCompleted="onStepCompleted"
        @stepBack="onStepBack" />
    </ez-wizard>
  </div>
</template>
<style lang="scss" scoped>
.container-wider {
  :deep() main {
    max-width: 1200px;
  }
}
</style>
