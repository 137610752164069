import i18n from '@/i18n';

export const OFFLINE_ORDER_STEP_1 = 0;
export const OFFLINE_ORDER_STEP_2 = 1;
export const OFFLINE_ORDER_STEP_3 = 2;

const steps = {
  [OFFLINE_ORDER_STEP_1]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venues/pending-updates/new-supplier/Supplier'),
    title: `Select a ${i18n.t('global.distributor')}`,
    pageTitle: `Please select a ${i18n.t('global.distributor')}`,
    pageInfo: 'Select which supplier delivered this order and when did it take place.',
    nextCta: 'Next Step',
  },
  [OFFLINE_ORDER_STEP_2]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venues/pending-updates/new-supplier/Products'),
    title: 'Products',
    pageTitle: 'Select now add products',
    pageInfo: 'Select products that you ordered from this supplier.',
    nextCta: 'Go to Overview',
  },
  [OFFLINE_ORDER_STEP_3]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venues/pending-updates/new-supplier/Overview'),
    title: 'Overview',
    pageTitle: 'Order Overview',
    pageInfo: 'Before you submit this order, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Submit Order',
  },
};

export default steps;
