<template>
  <single-page>
    <template #title>
      <header class="clearing-app__header">
        <h1>Clearing App</h1>
      </header>
    </template>
    <template #navigation>
      <ul>
        <li>
          <router-link :to="{ name: 'admin-clearing-app-processing' }">
            Processing
          </router-link>
        </li>
      </ul>
    </template>
  </single-page>
</template>

<script>
import SinglePage from '@/views/admin/SinglePage.vue';

export default {
  components: {
    SinglePage,
  },
};
</script>

<style scoped lang="scss">
.clearing-app__header {
  h1 {
    font-size: 24px;
    font-weight: 500;
  }
}
</style>
