<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VPdfViewer from '@/components/v3/elements/VPdfViewer';

/**
*
* @version 1.0.0
* @since 3.0.0
*/
export default {
  components: {
    InnerImageZoom,
    VueSlickCarousel,
    VPdfViewer,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
<template>
  <VueSlickCarousel class="slider" :arrows="true" :dots="true" >
    <template v-for="item in data">
      <inner-image-zoom
        v-if="item.type !== 'pdf'"
        class="image-zoom"
        :src="item.image"
        :key="item.name"
        :alt="item.name"
      />
      <a v-else-if="item.type === 'pdf'" :key="item.name" :href="item.image" target="_blank" class="pdf-placeholder">
        <v-pdf-viewer
          :src="item.image"
        />
      </a>
    </template>
    <template #prevArrow>
      <div class="custom-arrow custom-arrow--left">
        <img src="@/assets/icons/ic-chevron-left.png" alt="">
      </div>
    </template>
    <template #nextArrow>
      <div class="custom-arrow custom-arrow--right">
        <img src="@/assets/icons/ic-chevron-right.png" alt="">
      </div>
    </template>
    <template #customPaging>
      <div class="custom-dot"></div>
    </template>
  </VueSlickCarousel>
</template>
<style lang="scss" scoped>
$height: 708px;
.slick-slider {
  :deep() .slick-slide {

    figure {
      max-height: $height;
      img {
        width: 100%;
      }
    }
  }
}
.slider {
  :deep() .slick-dots {
    bottom: 20px;
    li {
      width: 8px;
      height: 8px;
    }
    .custom-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fff;
      opacity: .4;
    }
    .slick-active {
      .custom-dot {
        opacity: 1;
      }
    }
  }
}
.custom-arrow {
  z-index: 100;
  background: #FFFFFF;
  border: 1px solid #ECEEF5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--left {
    left: 25px;
  }
  &--right {
    right: 25px;
  }
  &::before {
    content: none;
  }
}

.pdf-placeholder {
  height: $height;
  overflow-x: scroll;

  :deep() .pdf-container {
    svg {
      font-size: 120px;
    }
  }

  :deep() .pdf-page-canvas {
    width: 100%;
  }
}
</style>
