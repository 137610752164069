<template>
  <set-password action="/admin/set-password">
    <template #backToLogin>
      <router-link :to="{ name: 'admin-login' }">Sign In.</router-link>
    </template>
  </set-password>
</template>

<script>
import { SetPassword } from '@/views/common/auth';

export default {
  components: {
    SetPassword,
  },
};
</script>
