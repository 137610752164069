<script>
import EzAddressForm from '@/views/common/address/Form';
import EzForm from '@/components/ui/Form/EzForm';
import EzInput from '@/components/ui/Input/EzInput';
import EzImageUpload from '@/components/ui/ImageUpload/EzImageUpload';
import { mapGetters } from 'vuex';
import { falsy } from '@/util/utils';

/**
*
* @version 1.0.0
* @since 3.0.0
*/
export default {
  components: {
    EzAddressForm,
    EzForm,
    EzInput,
    EzImageUpload,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    supplier: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      formKey: 'newSupplier',
      imgModified: false,
      formValid: false,
      requiredValues: {
        name: null,
        primaryContactName: null,
        primaryContactPhone: null,
        address: null,
        country: null,
        city: null,
      },
    };
  },
  computed: {
    ...mapGetters('defaultImages', [
      'getDefaultImage',
    ]),
    requiredAddressFields() {
      return {
        street: 'required',
        city: 'required',
        country: 'required',
      };
    },
    defaultImage() {
      return this.getDefaultImage('distributor');
    },
    distributor() {
      return this.supplier || {};
    },
    isEdit() {
      return Object.keys(this.distributor).length;
    },
    action() {
      return this.isEdit ? this.editAction : this.createAction;
    },
    createAction() {
      return '/admin/distributors';
    },
    editAction() {
      return `/admin/distributors/${this.distributor.id}`;
    },
    method() {
      return this.isEdit ? 'patch' : 'post';
    },
  },
  watch: {
    formValid() {
      this.$emit('formValid', this.formValid);
    },
    async distributor() {
      await this.initialFormState();
      this.validateForm();
    },
  },
  methods: {
    onSubmitSuccess(response) {
      this.$emit('submitSuccess', response);
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
    onRemoveImage() {
      this.imgModified = true;
    },
    updateFormState(name, value) {
      this.requiredValues[name] = value;
      this.validateForm();
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach((key) => {
        if (falsy(this.requiredValues[key])) this.formValid = false;
      });
    },
    async initialFormState() {
      await this.$nextTick();
      this.requiredValues = {
        name: this.distributor.name || this.name,
        primaryContactName: this.distributor.primaryContactName,
        primaryContactPhone: this.distributor.primaryContactPhone,
        address: (this.distributor.address || {}).street,
        country: (this.distributor.address || {}).country,
        city: (this.distributor.address || {}).city,
      };
    },
  },
  async mounted() {
    await this.initialFormState();
    this.validateForm();
  },
};
</script>
<template>
  <ez-form
    :action="action"
    :formKey="formKey"
    :method="method"
    :additional-data="{...(imgModified && {logo: ''})}"
    submitType="multipart"
    @success="onSubmitSuccess"
    @error="onSubmitError"
    ref="distributorForm"
    class="distributor-form"
  >
    <ez-image-upload
      :formKey="formKey"
      :isRound="true"
      :previewUrl="distributor.logo || defaultImage"
      :isDefaultImage="true"
      :columnMode="true"
      @change="imgModified = true"
      @removeImage="onRemoveImage"
      name="logo"
    >
      <template #addImage>
        <span>Add {{ $t('global.distributor') }} Logo</span>
      </template>
      <template #removeImage>
        <font-awesome-icon icon="times"/>
        <span>Remove Logo</span>
      </template>
    </ez-image-upload>
    <ez-input
      :formKey="formKey"
      :value="distributor.name || name"
      @onChange="updateFormState('name', $event)"
      validators="required"
      :label="`${$t('global.distributor')} name*`"
      :placeholder="`Enter ${$t('global.distributor')} name`"
      name="name"
    />

    <hr class="address-separator">

    <ez-address-form
      :fieldValidators="requiredAddressFields"
      @onChange="updateFormState('address', $event)"
      @city="updateFormState('city', $event)"
      @country="updateFormState('country', $event)"
      :formKey="formKey"
      :address="distributor.address || {}"
      name="address"
    />
  </ez-form>
</template>
<style lang="scss" scoped>
.primary-info {
  display: flex;
  align-items: center;
  .input-group {
    flex: 1 1 100%;
    &:first-child {
      margin-right: 12px;
    }
  }
}
</style>
