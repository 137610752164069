<template>
  <login action="/admin/oauth" @loginSuccess="handleLoginSuccess">
    <template #resetPassword>
      <router-link :to="{ name: 'admin-forgot-password'}">Forgot Password?</router-link>
    </template>
  </login>
</template>

<script>
import { Login } from '@/views/common/auth';
import { mapActions } from 'vuex';

export default {
  components: {
    Login,
  },
  methods: {
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    handleLoginSuccess({ data: { data } }) {
      const {
        accessToken,
        accessTokenExpiresAt,
        refreshToken,
        refreshTokenExpiresAt,
        scope,
        user,
      } = data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
      localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiresAt);
      localStorage.setItem('role', scope);
      localStorage.setItem('permissions', JSON.stringify(user.permissions));

      this.fetchDefaultImages();
      this.$router.push({ name: 'admin-home' });
    },
  },
};
</script>

<style scoped lang="scss">
  :deep() .auth__form h1 {
    margin: 0 0 1.33em;
  }
</style>
