<template>
  <ez-drawer ref="drawer" class="admin-single-order" @onClose="onDrawerClose">
    <template #title>
      <h2>Order Request</h2>
      <status-badge v-if="order" :status="order.status" />
    </template>
    <template #content>
      <single-order :order="order">
        <template #venue>
          <ez-entity-info
            v-if="order.venue"
            :imgUrl="order.venue.logo"
            imgWidth="2rem"
            imgHeight="2rem"
            imgBorderRadius="50%"
          >
            <router-link
              :to="{
                name: 'admin-venue-info',
                params: { id: order.venue.id, groupId: order.venue.groupId },
              }"
            >
              {{ order.venue.name }}
            </router-link>
          </ez-entity-info>
        </template>
        <template #distributor>
          <ez-entity-info
            v-if="order.distributor"
            :imgUrl="order.distributor.logo"
            imgWidth="2rem"
            imgHeight="2rem"
            imgBorderRadius="50%"
          >
            <router-link
              :to="{
                name: 'admin-distributor-info',
                params: { id: order.distributor.id },
              }"
            >
              {{ order.distributor.name }}
            </router-link>
          </ez-entity-info>
        </template>
      </single-order>
    </template>
    <template #footer>
      <export-button v-if="order && order.status !== ORDER_STATUS_PENDING" :order="order" />
      <div class="order-total-price">
        <v-subtotal-info
          :item-list="itemList"
          :delivery-fee="deliveryFee"
          :tax="tax"
          :delivery-fee-taxable="deliveryFeeTaxable"
          :amount-adjustment="amountAdjustment"
          :tax-adjustment="taxAdjustment"
          :is-tbd="isSomeTbd"
          :discount="orderDiscountAmount"
          :taxCalculation="taxCalculation"
        />
      </div>
    </template>
  </ez-drawer>
</template>

<script>
import EzDrawer from '@/components/ui/Drawer';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { SingleOrder, ExportButton } from '@/views/common/orders';
import StatusBadge from '@/views/common/status-badge';
import { ORDER_STATUS_PENDING } from '@/util/constants';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import { mapMutations } from 'vuex';
import { isDeclined, isExcluded } from '@/util/utils';

export default {
  components: {
    VSubtotalInfo,
    EzDrawer,
    EzEntityInfo,
    ExportButton,
    StatusBadge,
    SingleOrder,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ORDER_STATUS_PENDING,
    };
  },
  computed: {
    // Props for VSubtotalInfo - Start
    itemList() {
      const { orderedProducts: products } = this.order || {};
      return products?.filter(prod => !isExcluded(prod) && !isDeclined(prod)) || [];
    },
    deliveryFee() {
      return this.order?.deliveryFee || 0;
    },
    deliveryFeeTaxable() {
      return this.order?.deliveryFeeWithoutTax;
    },
    amountAdjustment() {
      return this.order?.amountAdjustment || 0;
    },
    taxAdjustment() {
      return this.order?.taxAdjustment || 0;
    },
    isSomeTbd() {
      return (this.order?.orderedProducts || [])
        .filter(pr => !isDeclined(pr))
        .some(pr => pr.marketPrice || (!pr.priceQuantity && pr.price > 0));
    },
    orderDiscountAmount() {
      return this.order?.orderDiscountAmount;
    },
    tax() {
      return this.order?.tax || 0;
    },
    taxCalculation() {
      return this.order?.taxCalculation;
    },
    // Props for VSubtotalInfo - End
  },
  methods: {
    ...mapMutations('entities/orders', ['SET_SELECTED_SINGLE_ORDER']),
    openDrawer() {
      this.$refs.drawer.open();
    },
    onDrawerClose() {
      this.$router.replace({ name: 'admin-order-history' });
    },
  },
  watch: {
    order(newVal) {
      this.SET_SELECTED_SINGLE_ORDER(newVal);
    },
  },
};
</script>
<style scoped lang="scss">
:deep() .drawer {
  @extend %order-drawer;
}
</style>
