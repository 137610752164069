<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VPdfViewer from '@/components/v3/elements/VPdfViewer';
import uuid from 'uuid/v4';

/**
 *
 * @version 1.0.0
 * @since 3.0.0
 */
export default {
  components: {
    VPdfViewer,
    InnerImageZoom,
    VueSlickCarousel,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      uuid,
    };
  },
};
</script>
<template>
  <vue-slick-carousel v-if="data.length" class="slider" :arrows="true" :dots="true">
    <template v-for="item in data">
      <inner-image-zoom
        v-if="item.type !== 'pdf'"
        class="image-zoom"
        :src="item.image"
        :key="item.name"
        :alt="item.name"
      />
      <a
        v-if="item.type === 'pdf'"
        :key="item.name"
        :href="item.image"
        target="_blank"
        class="pdf-placeholder"
      >
        <v-pdf-viewer :src="item.image" :controls="true" :id="uuid()" :rotate="false" />
      </a>
    </template>
    <template #prevArrow>
      <div class="custom-arrow custom-arrow--left">
        <img src="@/assets/icons/ic-chevron-left.png" alt="" />
      </div>
    </template>
    <template #nextArrow>
      <div class="custom-arrow custom-arrow--right">
        <img src="@/assets/icons/ic-chevron-right.png" alt="" />
      </div>
    </template>
    <template #customPaging>
      <div class="custom-dot"></div>
    </template>
  </vue-slick-carousel>
</template>
<style lang="scss" scoped>
$height: 708px;

.slick-slider {
  :deep() .slick-slide {
    figure {
      max-height: $height;
      img {
        width: 100%;
      }
    }
  }
}

.pdf-placeholder {
  height: $height;
  overflow-x: scroll;

  :deep() .pdf-container {
    svg {
      font-size: 120px;
    }
  }
}

.slider {
  :deep() .slick-dots {
    bottom: 20px;
    li {
      width: 8px;
      height: 8px;
    }
    .custom-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fff;
      opacity: 0.4;
    }
    .slick-active {
      .custom-dot {
        opacity: 1;
      }
    }
  }
}
.custom-arrow {
  z-index: 100;
  background: #ffffff;
  border: 1px solid #eceef5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--left {
    left: 25px;
  }
  &--right {
    right: 25px;
  }
  &::before {
    content: none;
  }
}
</style>
