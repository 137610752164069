<script>
import { mapActions, mapGetters } from 'vuex';
import EzTable from '@/components/ui/Table/EzTable.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index.vue';
import EzDropdown from '@/components/ui/Dropdown';
import dayjs from 'dayjs';
import { debounce } from '@/util/utils';
import {
  ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED,
  ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED,
  ADMIN_INVOICE_PROCESSING_STATUS_CLEARING_READY,
  ADMIN_INVOICE_PROCESSING_STATUS_IN_CLEARING,
  ADMIN_INVOICE_PROCESSING_STATUS_REVIEW_READY,
  ADMIN_INVOICE_PROCESSING_STATUS_IN_REVIEW,
  LOADING_KEY,
} from '@/util/constants';
import httpService from '@/api/http';

export default {
  components: {
    EzTable,
    EzEntityInfo,
    VAccountOwnerEntityInfo,
    StatusBadge,
    EzLoadMore,
    EzSpinner,
    EzFilterList,
    EzInput,
    VDatePicker,
    VSelectSearch,
    EzDropdown,
  },
  data() {
    return {
      pendingInvoices: [],
      processingStatuses: [],
      meta: {},
      headers: {
        createdAt: () => 'Upload Date',
        priority: () => 'Tags',
        group: () => 'Organization',
        venue: () => 'Location',
        clearingUser: () => 'Assigned To',
        processingStatus: () => 'Status',
      },
      dayjs,
      ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED,
      ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED,
      ADMIN_INVOICE_PROCESSING_STATUS_CLEARING_READY,
      ADMIN_INVOICE_PROCESSING_STATUS_IN_CLEARING,
      ADMIN_INVOICE_PROCESSING_STATUS_REVIEW_READY,
      ADMIN_INVOICE_PROCESSING_STATUS_IN_REVIEW,
      filters: {
        term: null,
        groupId: null,
        processingStatus: null,
        to: null,
        from: null,
      },
      groups: [],
      groupData: {},
      range: {
        start: dayjs().subtract(7, 'd'),
        end: new Date(),
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.FETCH_ADMIN_PENDING_INVOICES]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_ADMIN_PENDING_INVOICES_LOAD_MORE);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['adminFetchPendingInvoices']),
    ...mapActions('entities/groups', ['fetchPendingInvoicesVenueGroups']),
    async loadMore() {
      const { data } = await this.adminFetchPendingInvoices({
        page: this.meta.page + 1,
        loadingKey: LOADING_KEY.FETCH_ADMIN_PENDING_INVOICES_LOAD_MORE,
        ...this.filters,
      });
      this.pendingInvoices = [...this.pendingInvoices, ...data.data];
      this.meta = data.meta;
    },
    async resetFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      localStorage.removeItem('processingFilters');
      this.pendingInvoices = [];
      this.meta = {};
      const { data } = await this.adminFetchPendingInvoices();
      this.pendingInvoices = data.data;
      this.meta = data.meta;
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
        localStorage.setItem(
          'processingFilters',
          JSON.stringify({
            ...this.filters,
            term: event.length ? event : null,
          }),
        );
      }
      if (filterName === 'month') {
        this.filters = {
          ...this.filters,
          from: dayjs(this.range.start).valueOf(),
          to: dayjs(this.range.end).valueOf(),
        };
        localStorage.setItem(
          'processingFilters',
          JSON.stringify({
            ...this.filters,
            from: dayjs(this.range.start).valueOf(),
            to: dayjs(this.range.end).valueOf(),
          }),
        );
      } else {
        this.filters = {
          ...this.filters,
          [filterName]: event.id,
        };
        localStorage.setItem(
          'processingFilters',
          JSON.stringify({
            ...this.filters,
            [filterName]: event.id,
          }),
        );
      }

      this.pendingInvoices = [];
      this.meta = {};
      const { data } = await this.adminFetchPendingInvoices(this.filters);
      this.pendingInvoices = data.data;
      this.meta = data.meta;
    }, 300),
    async openSinglePendingInvoice({ id, venue, noteKey, processingStatus }) {
      if (noteKey === 'missing_page') {
        await httpService.put(`admin/pending-invoices/${id}/user`);
        const routerData = this.$router.resolve({
          name: 'admin-clearing-app-merge',
          params: {
            id,
          },
          query: {
            venueId: venue.id,
          },
        });
        window.open(routerData.href, '_blank');
        return;
      }
      const routerData = this.$router.resolve({
        name: 'admin-clearing-app-processing-single',
        params: {
          id,
        },
        query: {
          status: processingStatus,
        },
      });
      window.open(routerData.href, '_blank');
    },
  },
  async mounted() {
    if (localStorage.getItem('processingFilters')) {
      this.filters = JSON.parse(localStorage.getItem('processingFilters'));
      if (this.filters.from) this.range.start = this.filters?.from;
      if (this.filters.to) this.range.end = this.filters?.to;
    }
    const { data } = await this.adminFetchPendingInvoices(this.filters);
    this.pendingInvoices = data.data;
    this.meta = data.meta;
    const { data: groupData } = await this.fetchPendingInvoicesVenueGroups();
    this.groups = [...this.groups, ...groupData.data];
    this.processingStatuses = [
      {
        id: null,
        name: 'All Statuses',
      },
      ...data.meta.processingStatuses.map(item => ({ id: item.key, name: item.value })),
    ];
  },
};
</script>

<template>
  <div>
    <div class="filters-actions">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          class="search"
          :value="filters.term"
          placeholder="Invoice No"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
        <ez-dropdown
          class="ml-12"
          name="processingStatus"
          formKey="filters"
          :data="processingStatuses"
          :selected="filters.processingStatus"
          placeholder="All Statuses"
        />
        <v-select-search
          class="ml-12"
          formKey="filters"
          name="groupId"
          :data="groups"
          search="/admin/venue-groups"
          :searchLimit="30"
          placeholder="Organization"
          :searchPlaceholder="`Select Organization`"
          :selected="filters.groupId ? filters.groupId : ''"
          :min-length="3"
        >
          <template #result="{ result }">
            <ez-entity-info
              :imgUrl="result.logo"
              imgWidth="2rem"
              imgHeight="2rem"
              imgBorderRadius="50%"
            >
              <span class="entity-info__name">{{ result.name }}</span>
            </ez-entity-info>
          </template>
        </v-select-search>
        <v-date-picker
          class="ml-12 calendar"
          name="month"
          label="Range"
          v-model="range"
          formKey="filters"
          range-mode
          hide-label
          with-predefined-ranges
          :numberOfCalendars="2"
          selectPredefinedRange="All Time"
          :displayEndOfRange="true"
        />
      </ez-filter-list>
    </div>
    <ez-table
      :data="pendingInvoices"
      :columns="[
        'createdAt',
        'priority',
        'group',
        'venue',
        'clearingUser',
        'processingStatus',
        'note',
      ]"
      :headers="headers"
      :loading="isLoading"
      :columnProps="{
        venue: { class: 'location-cell' },
        createdAt: { class: 'date-cell' },
        clearingUser: { class: 'medium-cell users-cell' },
        processingStatus: { class: 'status-cell' },
        priority: { class: 'medium-cell' },
      }"
      class="mt-24"
      @rowClick="openSinglePendingInvoice"
    >
      <template #cell-createdAt="{ row }">
        {{ dayjs(row.createdAt).format('DD/M/YYYY') }}
      </template>
      <template #cell-priority="{ row }">
        <status-badge :status="row.priority" />
      </template>
      <template #cell-group="{ row }">
        <ez-entity-info :imgUrl="row.group.logo" imgBorderRadius="50%">
          {{ row.group.name }}
        </ez-entity-info>
      </template>
      <template #cell-venue="{ row: { venue } }">
        {{ venue.name }}
      </template>
      <template #cell-clearingUser="{ row: { clearingUsers } }">
        <div class="clearing-users">
          <v-account-owner-entity-info
            v-for="clearingUser in clearingUsers"
            :key="clearingUser.id"
            class="clearing-users__user"
            :account-owner="clearingUser"
            :to-show-name="false"
          />
        </div>
      </template>
      <template #cell-processingStatus="{ row: { processingStatus, noteKey } }">
        <status-badge :status="processingStatus">
          <template #prefix>
            <img
              v-if="processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_IN_CLEARING"
              src="@/assets/icons/ic_file-loading.svg"
              alt="Blue file icon with white loader"
              class="mr-4"
            />
            <img
              v-else-if="processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_IN_REVIEW"
              src="@/assets/icons/ic_file-loading.svg"
              alt="Blue file icon with white loader"
              class="mr-4"
            />
            <img
              v-else-if="processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_CLEARING_READY"
              src="@/assets/icons/ic_file-check-purple.svg"
              alt="Purple file icon with white check mark"
              class="mr-4"
            />
            <img
              v-else-if="processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_REVIEW_READY"
              src="@/assets/icons/ic_file-check-purple.svg"
              alt="Purple file icon with white check mark"
              class="mr-4"
            />
            <img
              v-else-if="processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED"
              src="@/assets/icons/ic_file-check.svg"
              alt="Green file icon with white check mark"
              class="mr-4"
            />
            <img
              v-else-if="
                processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED &&
                noteKey === 'not_valid'
              "
              src="@/assets/icons/ic_file-not-valid.svg"
              alt="Red file icon with X mark"
              class="mr-4"
            />
            <img
              v-else-if="
                processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED &&
                noteKey === 'missing_info'
              "
              src="@/assets/icons/ic_file-info-missing.svg"
              alt="Red file icon with white exclamation mark"
              class="mr-4"
            />
            <img
              v-else-if="
                processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED &&
                noteKey === 'out_of_invoices'
              "
              src="@/assets/icons/ic_file-out-of-invoices.svg"
              alt="Red file icon with white exclamation mark"
              class="mr-4"
            />
            <img
              v-else-if="
                processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED &&
                noteKey === 'missing_page'
              "
              src="@/assets/icons/ic_file-page-missing.svg"
              alt="Red file icon with white exclamation mark"
              class="mr-4"
            />
            <img
              v-else-if="
                processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED &&
                noteKey === 'blurry_image'
              "
              src="@/assets/icons/ic_file-blurry.svg"
              alt="Red file icon with white exclamation mark"
              class="mr-4"
            />
            <img
              v-else-if="processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED"
              src="@/assets/icons/ic_file-exclamation.svg"
              alt="Red file icon with white exclamation mark"
              class="mr-4"
            />
          </template>
        </status-badge>
      </template>
      <template #cell-note="{ row: { note } }">
        <span v-if="note">{{ note }}</span>
        <span v-else>-</span>
      </template>
    </ez-table>
    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <ez-load-more v-if="!isLoadingMore && meta.lastPage > meta.page" @loadMore="loadMore" />
  </div>
</template>

<style scoped lang="scss">
table.table tbody tr {
  :deep() td.location-cell {
    color: $color-gray-25;
  }
}

table.table tbody tr {
  :deep() td {
    height: 56px;

    &.status-cell {
      height: auto;
      line-height: initial;

      .status {
        display: inline-flex;
        align-items: center;
        padding: 3px 6px;
      }
    }

    &.users-cell {
      overflow: visible;

      .clearing-users {
        display: flex;
        align-items: center;

        &__user {
          border: 2px solid white;
          border-radius: 50%;

          &:not(:first-child) {
            margin-left: -8px;
          }
        }
      }
    }
  }
}

:deep() .select-search {
  color: $color-gray-6C;

  button {
    @include font-size(14px);
  }

  &__list {
    max-height: 244px;
  }
}

:deep() .status-badge__prefix img {
  margin-top: 1px;
}
</style>
