<template>
  <div>
    <ez-user-list
      role="venue"
      :users="users"
      :columns="['name', 'email', 'role', 'venue']"
      :columnProps="{
        venue: { class: 'venue-cell' },
        role: { class: 'role-cell' },
      }"
      :headers="{
        accountName: () => 'Venue',
        venue: () => $t('global.venue').toLowerCase(),
      }"
      @addUser="onAddUser"
      @userClick="onUserClick"
      @removeUser="onRemoveUser"
    >
      <ez-venue-user-modal
        ref="userModal"
        :formKey="formKey"
        :formAction="formAction"
        :groupId="draft.id"
        :user="selectedUser"
        :roles="roles"
        @success="onSubmitSuccess"
      />
    </ez-user-list>
    <div class="has-admin-note" v-if="!hasAdmins">
      <font-awesome-icon icon="info-circle" />
      <span>Note: Group admin needs to be added.</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import { clone } from '@/util/utils';
import EzUserList, { EzVenueUserModal } from '@/views/common/users';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzUserList,
    EzVenueUserModal,
  },
  data() {
    return {
      formKey: 'venue-user',
      users: [],
      roles: [],
      selectedRoleId: null,
      selectedUser: null,
      allowGroupPermissionsInternal: false,
    };
  },
  computed: {
    hasAdmins() {
      const users = this.users.filter(user => user.role.key === 'venue_admin');
      return users.length > 0;
    },
    formAction() {
      return `admin/venue-groups/${this.draft.id}/users`;
    },
    needsVenue() {
      return ['venue_manager', 'venue_orderer', 'venue_receiver'].includes(this.selectedRoleId);
    },
    ...mapState('entities/groups', ['draft']),
  },
  methods: {
    ...mapActions('entities/users', [
      'fetchAdminVenueUser',
      'fetchAdminVenueUsers',
      'removeAdminVenueUser',
      'fetchVenueUsers',
      'adminFetchUsers',
    ]),
    ...mapActions('roles', ['getAdminVenueRoles']),
    async fetchUsers() {
      const { data } = await this.adminFetchUsers({ groupId: this.draft.id });
      this.users = data.data;
    },
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    openModal() {
      this.$refs.userModal.open();
    },
    closeModal() {
      this.$refs.userModal.close();
    },
    onAddUser() {
      this.selectedUser = null;
      this.openModal();
    },
    onUserClick(user) {
      this.selectedUser = clone(user);
      this.openModal();
    },
    async onSubmitSuccess() {
      await this.fetchUsers();
    },
    async onRemoveUser({ id }) {
      await this.removeAdminVenueUser({ groupId: this.draft.id, id });
      await this.fetchUsers();
    },
  },
  watch: {
    hasAdmins(val) {
      if (val) this.enableNextStep();
      else this.disableNextStep();
    },
  },
  async mounted() {
    [this.roles] = await Promise.all([this.getAdminVenueRoles(), this.fetchUsers()]);
  },
};
</script>

<style scoped lang="scss">
.user-permissions {
  @include separator();
  .ez-dropdown + .ez-dropdown {
    margin-top: 0.75rem;
  }
  .ez-checkbox {
    margin: 1rem 0;
  }
}
.has-admin-note {
  @extend %flex-center;
  justify-content: center;
  margin-top: px-to-rem(16px);
  color: $color-gray-6C;
  svg {
    color: #b9bfcd;
    margin-right: px-to-rem(6px);
  }
  span {
    @include font-size(14px);
    font-weight: 400;
  }
}
</style>
