var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ez-drawer',{ref:"drawer",staticClass:"admin-single-order",on:{"onClose":_vm.onDrawerClose},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',[_vm._v("Order Request")]),(_vm.order)?_c('status-badge',{attrs:{"status":_vm.order.status}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('single-order',{attrs:{"order":_vm.order},scopedSlots:_vm._u([{key:"venue",fn:function(){return [(_vm.order.venue)?_c('ez-entity-info',{attrs:{"imgUrl":_vm.order.venue.logo,"imgWidth":"2rem","imgHeight":"2rem","imgBorderRadius":"50%"}},[_c('router-link',{attrs:{"to":{
              name: 'admin-venue-info',
              params: { id: _vm.order.venue.id, groupId: _vm.order.venue.groupId },
            }}},[_vm._v(" "+_vm._s(_vm.order.venue.name)+" ")])],1):_vm._e()]},proxy:true},{key:"distributor",fn:function(){return [(_vm.order.distributor)?_c('ez-entity-info',{attrs:{"imgUrl":_vm.order.distributor.logo,"imgWidth":"2rem","imgHeight":"2rem","imgBorderRadius":"50%"}},[_c('router-link',{attrs:{"to":{
              name: 'admin-distributor-info',
              params: { id: _vm.order.distributor.id },
            }}},[_vm._v(" "+_vm._s(_vm.order.distributor.name)+" ")])],1):_vm._e()]},proxy:true}])})]},proxy:true},{key:"footer",fn:function(){return [(_vm.order && _vm.order.status !== _vm.ORDER_STATUS_PENDING)?_c('export-button',{attrs:{"order":_vm.order}}):_vm._e(),_c('div',{staticClass:"order-total-price"},[_c('v-subtotal-info',{attrs:{"item-list":_vm.itemList,"delivery-fee":_vm.deliveryFee,"tax":_vm.tax,"delivery-fee-taxable":_vm.deliveryFeeTaxable,"amount-adjustment":_vm.amountAdjustment,"tax-adjustment":_vm.taxAdjustment,"is-tbd":_vm.isSomeTbd,"discount":_vm.orderDiscountAmount,"taxCalculation":_vm.taxCalculation}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }