<template>
  <div class="order-list">
    <div class="order-list__header-wrapper">
      <Header
        v-if="hasStats"
        to-hide-total
        :count="meta.totalCount"
        :amount="meta.totalAmount"
        :filters-applied="filters"
      />
      <div class="order-list__filters">
        <slot v-if="hasSlotFiltersGroup" name="filtersGroup"></slot>
        <ez-filter-list
          v-else
          :filters="filters"
          @resetFilter="resetFilters"
          @filterUpdated="updateFilters"
        >
          <slot name="filters"></slot>
        </ez-filter-list>
        <div class="order-list__filters-action">
          <slot name="actions" />
        </div>
      </div>
    </div>
    <div v-if="hasOrders" class="order-list__content-wrapper">
      <div class="order-list__content">
        <slot name="banner"></slot>
        <div
          v-for="(group, id) in groupedOrders"
          :key="id"
          class="order-list__group"
        >
          <h2>{{ group.title }}</h2>
          <ez-table
            :data="group.items"
            :headers="headers"
            :columns="columns"
            :columnProps="{
              orderNumber: {
                class: showInvoiceNumber
                  ? 'orderNumber-cell large-cell'
                  : 'small-cell',
              },
              statusIcon: { class: 'small-cell text-overflow--clip' },
              orderedAt: { class: 'date-cell medium-cell' },
              expectedAt: { class: 'date-cell medium-cell' },
              status: { class: 'extra-large-cell' },
              amount: { class: 'price-cell' },
            }"
            @rowClick="onRowClick"
          >
            <template #cell-orderNumber="{ row }">
              <span
                class="order-number"
                :data-cy="`${commonCy.ORDERS.TEXT__ORDER_NUMBER}-${row.id}`"
              >
                {{ row.orderNumber }}
              </span>
              <span
                v-if="
                  showInvoiceNumber &&
                  (row.invoiceNumber || row.distributorOrderNumber)
                "
              >
                {{ row.invoiceNumber || row.distributorOrderNumber }}
              </span>
            </template>
            <template #cell-statusIcon="{ row }">
              <div class="order-list__status-icons">
                <font-awesome-icon
                  v-if="row.marketPrice"
                  v-tooltip="{
                    placement: 'top-end',
                    content: $t('product.marketPriceOrderInfo'),
                    classes: ['tooltip-general', 'tooltip--lift-up'],
                  }"
                  icon="comment-dollar"
                />
                <font-awesome-icon
                  v-if="
                    row.status === ORDER_STATUS_PENDING && row.afterWorkingHours
                  "
                  v-tooltip="{
                    placement: 'top-end',
                    content: `This order was placed after work hours.`,
                    classes: ['tooltip-general', 'tooltip--lift-up'],
                  }"
                  :icon="{ prefix: 'fas', iconName: 'clock' }"
                />
                <img
                  v-if="
                    row.invoiceProcessingStatus ===
                    INVOICE_PROCESSING_STATUS_PROCESSED
                  "
                  src="@/assets/icons/ic_file-check.svg"
                  alt="Green file icon with white checkmark"
                  :data-cy="`${commonCy.ORDERS.ICON__INVOICE_PROCESSING_STATUS}-processed-${row.id}`"
                  v-tooltip="{
                    placement: 'top-end',
                    content: 'INV processed',
                    classes: ['tooltip-general', 'tooltip--lift-up'],
                  }"
                />
                <img
                  v-if="
                    row.invoiceProcessingStatus ===
                    INVOICE_PROCESSING_STATUS_ACTION_NEEDED
                  "
                  src="@/assets/icons/ic_file-exclamation.svg"
                  alt="Red file icon with white exclamation mark"
                  :data-cy="`${commonCy.ORDERS.ICON__INVOICE_PROCESSING_STATUS}-actionNeeded-${row.id}`"
                  v-tooltip="{
                    placement: 'top-end',
                    content: 'INV action needed',
                    classes: ['tooltip-general', 'tooltip--lift-up'],
                  }"
                />
                <img
                  v-if="
                    row.invoiceProcessingStatus ===
                    INVOICE_PROCESSING_STATUS_PROCESSING
                  "
                  src="@/assets/icons/ic_file-loading.svg"
                  alt="Blue file icon with white loader"
                  :data-cy="`${commonCy.ORDERS.ICON__INVOICE_PROCESSING_STATUS}-processing-${row.id}`"
                  v-tooltip="{
                    placement: 'top-end',
                    content: 'INV processing',
                    classes: ['tooltip-general', 'tooltip--lift-up'],
                  }"
                />
              </div>
            </template>
            <template #cell-venue="{ row: { venue, id } }">
              <v-venue-entity-info
                :venue="venue || {}"
                :data-cy="`${commonCy.ORDERS.TEXT__OUTLET_NAME}-${id}`"
              />
            </template>
            <template #cell-distributor="{ row: { distributor, id } }">
              <v-distributor-entity-info
                :distributor="distributor || {}"
                :data-cy="`${commonCy.ORDERS.TEXT__DISTRIBUTOR_NAME}-${id}`"
              />
            </template>
            <template #cell-orderedAt="{ row }">
              <span :data-cy="`${commonCy.ORDERS.TEXT__ORDER_DATE}-${row.id}`">
                {{ row.orderedAt | date }}
              </span>
            </template>
            <template #cell-expectedAt="{ row }">
              <span
                :data-cy="`${commonCy.ORDERS.TEXT__DELIVERY_DATE}-${row.id}`"
              >
                {{ row.expectedAt | date }}
              </span>
            </template>
            <template #cell-status="{ row }">
              <status-badge
                :status="row.status || 'pending'"
                :data-cy="`${commonCy.ORDERS.TEXT__STATUS}-${row.id}`"
              />
            </template>
            <template #cell-amount="{ row, row: { currency } }">
              <v-price
                :price="row.amountWithTax || 0"
                :currency="currency"
                :show-market-price-info="false"
                :is-to-be-determined="row.tbd"
                :data-cy="`${commonCy.ORDERS.TEXT__AMOUNT}-${row.id}`"
              />
            </template>
          </ez-table>
        </div>
      </div>
      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>
      <ez-load-more
        v-if="!isLoadingMore && meta.nextId"
        @loadMore="onLoadMoreClick"
      />
    </div>
    <template v-else-if="!isLoading">
      <div v-if="hasFilters">
        <empty-state class="order-list-no-orders">
          <template #badge
            ><img src="@/assets/no-orders-illustration.svg" alt=""
          /></template>
          <template #title>No orders match these filters</template>
          <template #info>Try with different filters.</template>
        </empty-state>
      </div>
      <div v-else>
        <empty-state class="order-list-no-orders">
          <template #badge
            ><img src="@/assets/no-orders-illustration.svg" alt=""
          /></template>
          <template #title>No orders</template>
          <template #info
            >All orders will appear here once they are created.</template
          >
        </empty-state>
      </div>
    </template>
    <ez-loader :show="isLoading">Loading...</ez-loader>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import EzFilterList from '@/components/ui/FilterList';
import EzTable from '@/components/ui/Table';
import EzLoadMore from '@/components/ui/LoadMore';
import StatusBadge from '@/views/common/status-badge';
import EmptyState from '@/views/common/empty-state';
import Header from '@/views/platform/venue/orders/Header.vue';
import {
  LOADING_KEY,
  ORDER_STATUS_PENDING,
  INVOICE_PROCESSING_STATUS_PROCESSED,
  INVOICE_PROCESSING_STATUS_ACTION_NEEDED,
  INVOICE_PROCESSING_STATUS_PROCESSING,
} from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzLoader from '@/components/ui/Loader';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import VPrice from '@/components/v3/elements/VPrice';

export default {
  components: {
    EzSpinner,
    VDistributorEntityInfo,
    VVenueEntityInfo,
    EzFilterList,
    EzLoadMore,
    EzTable,
    StatusBadge,
    EmptyState,
    Header,
    EzLoader,
    VPrice,
  },
  data() {
    return {
      ORDER_STATUS_PENDING,
      commonCy,
      INVOICE_PROCESSING_STATUS_PROCESSING,
      INVOICE_PROCESSING_STATUS_PROCESSED,
      INVOICE_PROCESSING_STATUS_ACTION_NEEDED,
    };
  },
  props: {
    headers: {
      type: Object,
      default: () => ({
        orderNumber: () => 'Order No.',
        orderedAt: () => 'Order Date',
        amount: () => 'Total Amount',
        expectedAt: () => 'Delivery Date',
      }),
    },
    hasStats: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
    },
    showInvoiceNumber: {
      required: false,
      type: Boolean,
      default: false,
    },
    defaultDateFilters: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    groupedOrders: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    hasSlotFiltersGroup() {
      return !!this.$slots.filtersGroup;
    },
    hasFilters() {
      return Object.entries(this.filters).reduce(
        (prevFilter, [, value]) => prevFilter || !!value,
        false,
      );
    },
    hasOrders() {
      return Object.keys(this.groupedOrders).length;
    },
    ...mapState('entities/orders', ['meta']),
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_ADMIN_ORDER_HISTORY_LOAD_MORE);
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.EXPORT_ORDER,
        LOADING_KEY.FETCH_ORDER,
        LOADING_KEY.FETCH_ADMIN_ORDER_HISTORY,
      ]);
    },
  },
  methods: {
    resetFilters($event) {
      this.$emit('resetFilters', $event);
    },
    updateFilters(filterName, $event) {
      this.$emit('updateFilters', filterName, $event);
    },
    onRowClick($event) {
      this.$emit('rowClick', $event);
    },
    onLoadMoreClick() {
      this.$emit('loadMore');
    },
  },
};
</script>

<style scoped lang="scss">
.order-list {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: $loading-min-height;
  height: 100%;
  flex: 1 1 auto;

  &__header-wrapper :deep() {
    .order-header__stat {
      flex-direction: column-reverse;

      small {
        text-transform: initial;
      }
    }
  }

  &__content-wrapper {
    flex: 1 1 auto;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
  }

  &__group {
    h2 {
      @include font-size(16px);
      font-weight: 500;
      margin: 0 0 1rem 0;
    }

    + .order-list__group {
      margin-top: 2rem;
    }
  }

  :deep() {
    .orderNumber-cell {
      span {
        display: block;

        &.order-number {
          font-weight: 600;
          color: $color-gray-25;
        }
      }
    }
  }
}

.order-list__status-icons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

:deep() .order-list-no-orders {
  margin: 5rem auto;

  .ez-empty-state__image {
    max-width: none;
    img {
      margin-bottom: px-to-rem(24px);
      width: 219px;
      height: 116px;
    }
  }
}

.working-hours-flag {
  width: 12px;
  height: 12px;
  background-color: #4d7cfe;
}

:deep() .table tbody tr td:first-child {
  position: relative;
  overflow: hidden;
}

.text-overflow--clip {
  text-overflow: clip !important;
}
</style>
