<template>
  <sidebar-layout>
    <template #nav>
      <section class="main-nav-container">
        <router-link :to="{ name: 'admin-home' }" class="logo-link">
          <img src="@/assets/logo-lite.svg" alt="EZ logo">
        </router-link>
        <ul class="main-nav">
          <li v-if="canManagePlatform">
            <router-link :to="{ name: 'admin-order-history' }">
              <font-awesome-icon icon="history"/>
              <span>Order history</span>
            </router-link>
          </li>
          <li v-if="canManagePlatform">
            <router-link :to="{ name: 'admin-distributors' }">
              <font-awesome-icon icon="truck"/>
              <span>{{ $t('global.distributors') }}</span>
            </router-link>
          </li>
          <li v-if="canManagePlatform">
            <router-link :to="{ name: 'admin-venue-groups' }">
              <font-awesome-icon icon="store-alt"/>
              <span>{{ $t('global.venues') }}</span>
            </router-link>
          </li>
          <li v-if="canManagePlatform">
            <router-link :to="{ name: 'admin-categories' }">
              <font-awesome-icon icon="tag"/>
              <span>Categories</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-clearing-app' }">
              <font-awesome-icon icon="file-invoice-dollar"/>
              <span>Clearing App</span>
            </router-link>
          </li>
          <li>
            <a href="/logout" @click.prevent="handleLogout">
              <font-awesome-icon icon="sign-out-alt"/>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </section>
    </template>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </sidebar-layout>
</template>

<script>
import { mapActions } from 'vuex';
import SidebarLayout from '@/components/layout/Sidebar.vue';

export default {
  name: 'Dashboard',
  components: {
    SidebarLayout,
  },
  computed: {
    canManagePlatform() {
      return this.$permission.has('adminManagePlatform');
    },
  },
  methods: {
    ...mapActions('entities/users', ['adminLogout']),
    ...mapActions('entities/categories', [
      'fetchCategories',
    ]),
    handleLogout() {
      this.adminLogout().finally(() => {
        localStorage.clear();

        this.$router.replace({ name: 'admin-login' });
      });
    },
  },
  async mounted() {
    if (this.canManagePlatform) await this.fetchCategories();
  },
};
</script>

<style scoped lang="scss">
$default-text-color: #FFFFFF;
$active-item-background: #282C3D;
$active-icon-color: #4d7cfe;
$separator-color: #303848;

.main-nav-container {
  @include font-size(14px);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .logo-link {
    border-bottom: 1px solid $separator-color;
    margin: 1.5rem 1.5rem 0;
    padding-bottom: 1.5rem;
  }
  .main-nav {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 0.75rem;
    li {
      list-style: none;
      display: block;
      margin: 0.125rem 0;

      &:last-child {
        margin-top: auto;
      }

      a {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, .64);
        text-decoration: none;
        padding: 0.75rem;
        border-radius: 0.1875em;

        &:hover,
        &.router-link-active {
          background-color: $active-item-background;
        }

        &:hover {
          color: rgba(255, 255, 255, .8);
          svg {
            color: rgba(255, 255, 255, .8);
          }
        }

        &.router-link-active {
          color: rgba(255, 255, 255, 1);
          svg {
            color: $active-icon-color;
          }
        }

        svg {
          color: rgba(255, 255, 255, .4);
          width: 1.25rem;
          height: 1.25rem;
        }

        span {
          margin-left: 0.75rem;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
