<template>
  <venue-list
    :groupId="draft.id"
    :venues="venues"
    editInModal
    @venueCreated="onVenueCreated"
    @loadMore="loadMoreVenues"
    :hasMoreVenues="!!meta.nextId && !!meta.nextValue"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import VenueList from '@/views/common/venues/List.vue';

export default {
  mixins: [wizardListenerMixin],
  components: {
    VenueList,
  },
  data() {
    return {
      venues: [],
      meta: {},
    };
  },
  computed: {
    ...mapState('entities/groups', ['draft']),
  },
  methods: {
    ...mapActions('entities/venues', ['fetchVenuesForGroupAdmin']),
    async fetchVenuesForGroup() {
      const { data } = await this.fetchVenuesForGroupAdmin({ groupId: this.draft.id });
      this.venues = data.data;
      this.meta = data.meta;
    },
    async onVenueCreated() {
      this.meta = {};
      await this.fetchVenuesForGroup();
    },
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    async loadMoreVenues() {
      const { data } = await this.fetchVenuesForGroupAdmin({
        groupId: this.draft.id,
        query: this.meta,
      });
      this.venues = [...this.venues, ...data.data];
      this.meta = data.meta;
    },
  },
  async mounted() {
    await this.fetchVenuesForGroup();
  },
  watch: {
    venues(val) {
      if (val.length) this.enableNextStep();
      else this.disableNextStep();
    },
  },
};
</script>

<style scoped>

</style>
