var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ez-user-list',{attrs:{"role":"venue","users":_vm.users,"columns":['name', 'email', 'role', 'venue'],"columnProps":{
      venue: { class: 'venue-cell' },
      role: { class: 'role-cell' },
    },"headers":{
      accountName: () => 'Venue',
      venue: () => _vm.$t('global.venue').toLowerCase(),
    }},on:{"addUser":_vm.onAddUser,"userClick":_vm.onUserClick,"removeUser":_vm.onRemoveUser}},[_c('ez-venue-user-modal',{ref:"userModal",attrs:{"formKey":_vm.formKey,"formAction":_vm.formAction,"groupId":_vm.draft.id,"user":_vm.selectedUser,"roles":_vm.roles},on:{"success":_vm.onSubmitSuccess}})],1),(!_vm.hasAdmins)?_c('div',{staticClass:"has-admin-note"},[_c('font-awesome-icon',{attrs:{"icon":"info-circle"}}),_c('span',[_vm._v("Note: Group admin needs to be added.")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }