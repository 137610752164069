<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapState, mapActions, mapMutations } from 'vuex';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzButton from '@/components/ui/Button/EzButton';
import EzTable from '@/components/ui/Table/EzTable';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import { EzCsvUploadModal } from '@/components/ui/Modal';
import ZoomSlider from '@/views/platform/venue/orders/pending-updates/new-supplier/ZoomSlider';
import ProductForm from '@/views/common/products/new-product-supplier/ProductForm';
import { clone, debounce } from '@/util/utils';
import toast from '@/components/ui/ToastMessage';
import EzInput from '@/components/ui/Input';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import Category from '@/models/Category';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import { calculateDeliveryFee } from '@/util/deliveryFee';
import { addTotalPriceProp, getTotalAmountQuantityPrice } from '@/util/utilsFinCalculator';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';

/**
 *
 * @version 1.0.0
 * @since 3.0.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    VSubtotalInfo,
    EzEntityInfo,
    EmptyState,
    EzCheckbox,
    EzButton,
    EzTable,
    EzMaskInput,
    EzCsvUploadModal,
    ZoomSlider,
    ProductForm,
    EzInput,
    EzLoadMore,
    EzMaskInputSimple,
  },
  data() {
    return {
      products: [],
      distributorProducts: null,
      availableProductsNum: null,
      term: '',
      meta: {},
      isCreateProduct: false,
      formValid: false,
      priceChangedProducts: [],
      totalAmount: 0,
    };
  },
  computed: {
    ...mapState('entities/venues', ['offlineOrderNewSupplier']),
    hasProducts() {
      return Object.keys(this.offlineOrderNewSupplier.products).length;
    },
    pendingUpdate() {
      return this.offlineOrderNewSupplier.pendingUpdate;
    },
    distributor() {
      return this.offlineOrderNewSupplier.supplier;
    },
    isPremiumDistributor() {
      return this.distributor.accountType === 'premium';
    },
    venueId() {
      return Number(this.$route.params.venueId);
    },
    isNewAddedSupplier() {
      return (
        !!this.distributor.address &&
        !!Object.keys(this.offlineOrderNewSupplier.supplier.address).length
      );
    },
    selectedProductIds() {
      return [...this.products.map(p => p.id)];
    },
    action() {
      return `/admin/distributors/${this.distributor.id}/products`;
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return addTotalPriceProp({ list: this.products });
    },
    tax() {
      return this.distributor?.tax || 0;
    },
    deliveryFee() {
      return calculateDeliveryFee(this.distributor, this.totalAmount);
    },
    // Props for VSubtotalInfo - End
    categories() {
      return Category.query().with('children').where('parentId', null).all();
    },
    hasSelectedProducts() {
      return (
        this.products.length &&
        !this.products.find(prod => prod.price <= 0) &&
        !this.products.find(prod => !prod.quantity)
      );
    },
  },
  methods: {
    onCheckboxChange(product, checked) {
      // TODO Change this, it is only an example
      toast.success('Product added below');
      if (checked) {
        setTimeout(() => {
          this.products.push({
            ...product,
            isNew: false,
            quantity: 1,
            isHidden: false,
          });
        }, 100);
        this.distributorProducts = this.distributorProducts.filter(prod => prod.id !== product.id);
        this.availableProductsNum -= 1;
      }
    },
    onSearch: debounce(async function deb(term) {
      if (term !== this.term) {
        this.term = term;
        this.meta = {};
        this.distributorProducts = await this.getDistributorProducts();
        this.distributorProducts = this.distributorProducts.filter(
          prod => !this.isProductSelected(prod),
        );
      }
    }, 400),
    async onLoadMore() {
      const products = await this.getDistributorProducts();
      this.distributorProducts = [...this.distributorProducts, ...products].filter(
        prod => !this.isProductSelected(prod),
      );
    },
    isProductSelected(product) {
      return !!this.products.find(seleProd => seleProd.id === product.id);
    },
    async getDistributorProducts() {
      const { nextId } = this.meta;
      const res = await this.fetchDistributorProductsForVenueAdmin({
        distributorId: this.distributor.id,
        term: this.term || null,
        nextId,
      });
      const { data, meta } = res.data;
      this.meta = meta;
      return data;
    },
    async removeItem(product) {
      this.products = this.products.filter(prod => prod.id !== product.id);
      this.priceChangedProducts = this.priceChangedProducts.filter(
        pr => pr.productId !== product.id,
      );

      if (product.isNew) {
        this.adminDeleteNewCreatedProduct({
          distributorId: this.distributor.id,
          productId: product.id,
        });
        return;
      }

      this.distributorProducts = [product, ...this.distributorProducts];
      this.availableProductsNum += 1;
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    uploadAction(data) {
      return this.adminUploadCsvProductsOfflineOrder({
        data,
        venueId: this.venueId,
        distributorId: this.distributor.id,
        pendingId: this.$route.params.pendingId,
      });
    },
    onUploadFinished(data) {
      this.products = [...this.products, ...(data.entities ? data.entities : [])];
    },
    createNewProduct() {
      this.isCreateProduct = true;
    },
    onFormClose() {
      this.isCreateProduct = false;
    },
    onSubmitSuccess({ data }) {
      this.products.push({
        ...data.data,
        isNew: true,
        quantity: 1,
      });
      this.onFormClose();
    },
    isFormValid(valid) {
      this.formValid = valid;
    },
    submitNewProduct() {
      this.$refs.formProduct.submit();
    },
    onPriceChange(product, newPrice) {
      if (product.isNew) {
        if (product.price === newPrice) return;
        const productTmp = product;
        if (this.priceChangedProducts.find(pr => pr.productId === productTmp.id)) {
          const pr = this.priceChangedProducts.find(prod => prod.productId === productTmp.id);
          const ind = this.priceChangedProducts.findIndex(p => p.productId === pr.productId);
          this.priceChangedProducts[ind].price = newPrice;
        } else {
          this.priceChangedProducts.push({
            productId: productTmp.id,
            price: newPrice,
          });
        }
        this.changeProductsPrice(product, newPrice);
      } else {
        this.changeProductsPrice(product, newPrice);
      }
    },
    changeProductsPrice(product, newPrice) {
      const productRef = this.products.find(pr => pr.id === product.id);
      productRef.price = newPrice;
    },
    onQuantityChange(quantity, id) {
      const productRef = this.products.find(pr => pr.id === id);
      productRef.quantity = quantity;
    },
    async onNextStep() {
      if (this.priceChangedProducts.length) {
        await this.adminProductsBulkUpdate({
          venueId: this.venueId,
          distributorId: this.distributor.id,
          data: this.priceChangedProducts,
        });
      }

      const visibilityChangeProducts = this.products.map(pro => ({
        productId: pro.id,
        visibility: true,
      }));
      await this.adminProductsBulkUpdate({
        venueId: this.venueId,
        distributorId: this.distributor.id,
        data: visibilityChangeProducts,
      });

      this.UPDATE_OFFLINE_PRODUCTS_NEW_SUPPLIER(this.products);
      this.$emit('stepCompleted');
    },
    sumTotalAmount() {
      this.totalAmount = getTotalAmountQuantityPrice(this.products);
    },
    ...mapActions('entities/distributors', [
      'adminUploadCsvProductsOfflineOrder',
      'adminDeleteNewCreatedProduct',
    ]),
    ...mapActions('entities/categories', ['venueFetchCategories']),
    ...mapActions('entities/venues', ['adminProductsBulkUpdate']),
    ...mapMutations('entities/venues', ['UPDATE_OFFLINE_PRODUCTS_NEW_SUPPLIER']),
    ...mapActions('entities/products', ['fetchDistributorProductsForVenueAdmin']),
  },
  watch: {
    products: {
      deep: true,
      handler() {
        this.sumTotalAmount();
        if (this.hasSelectedProducts) this.enableNextStep();
        else this.disableNextStep();
      },
    },
  },
  async created() {
    this.distributorProducts = await this.getDistributorProducts();
    this.distributorProducts = this.distributorProducts.filter(pro => !this.isProductSelected(pro));
    this.availableProductsNum = this.meta.totalCount - this.products.length;
  },
  async mounted() {
    if (this.hasProducts) {
      this.products = clone(this.offlineOrderNewSupplier.products);
      this.sumTotalAmount();
    }
  },
};
</script>
<template>
  <div>
    <div class="products">
      <div class="products__box">
        <zoom-slider :data="pendingUpdate.invoices" />
      </div>
      <div :class="['products__box', { 'new-supplier': isCreateProduct }]">
        <div v-if="!isCreateProduct">
          <div class="search-box__header">
            <h4 class="search-box__title">Available Products ({{ availableProductsNum }})</h4>
            <ez-input
              formKey="filters"
              name="search"
              class="search"
              placeholder="Search for a Product"
              :value="term"
              @onChange="onSearch"
            >
              <template #suffix>
                <font-awesome-icon icon="search" />
              </template>
            </ez-input>
          </div>
          <div class="search-box__body">
            <ez-table
              v-if="distributorProducts && distributorProducts.length"
              :data="distributorProducts"
              :columns="['product', 'price']"
              :headers="{
                product: () => 'Product',
                price: () => 'Price per Unit',
              }"
              :column-props="{
                price: { class: 'price-small-cell' },
              }"
            >
              <template #cell-product="{ row }">
                <div class="cell-product-container">
                  <ez-checkbox
                    class="cursor-pointer"
                    :key="row.id"
                    @change="onCheckboxChange(row, $event)"
                  />
                  <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
                    <div class="product-info" :title="row.name">
                      <span>{{ row.name }}</span>
                      <span class="product-info-secondary">
                        {{ row.sku }} &#8226; {{ row.category | categoryWithParent }}
                      </span>
                    </div>
                  </ez-entity-info>
                </div>
              </template>
              <template #cell-price="{ row }">
                <div class="price">
                  {{ row.price | price }}
                </div>
              </template>
            </ez-table>
            <empty-state v-else class="empty-state-suppliers">
              <template #badge>
                <img src="@/assets/search-product-state.svg" alt="" />
              </template>
            </empty-state>

            <ez-load-more v-if="meta.nextId" @loadMore="onLoadMore" />
          </div>
          <div v-if="!isPremiumDistributor" class="search-box__actions">
            <span class="search-box__msg"> Can’t find the product in the list? </span>
            <div>
              <ez-button
                class="mr-12"
                type="secondary"
                @click="createNewProduct"
                form-type="button"
              >
                <font-awesome-icon icon="plus" />
                Create New {{ $t('global.product') }}
              </ez-button>
              <ez-button type="secondary" @click="uploadCSVModal" form-type="button">
                <font-awesome-icon icon="upload" />
                Upload CSV
              </ez-button>
            </div>
          </div>
        </div>
        <div class="supplier-wrapper" v-else>
          <div class="title">
            {{ `Create new ${$t('global.product')}` }}
            <button class="remove-item" @click="onFormClose">
              <font-awesome-icon icon="times" />
            </button>
          </div>
          <div class="content">
            <product-form
              ref="formProduct"
              form-key="newProduct"
              class="new-product"
              method="post"
              :action="action"
              :categories="categories"
              :distributorId="distributor.id"
              @submitSuccess="onSubmitSuccess"
              @formValid="isFormValid"
            />
          </div>
          <div class="footer">
            <ez-button @click="onFormClose" type="link" formType="button">Cancel</ez-button>
            <ez-button :disabled="!formValid" @click="submitNewProduct">
              {{ `Create ${$t('global.product')}` }}
            </ez-button>
          </div>
        </div>
      </div>
    </div>
    <ez-table
      class="products-table"
      :data="products"
      :headers="{
        name: () => 'Product',
        price: () => 'Price per unit',
        action: () => '',
      }"
      :columns="['name', 'pricePerUnit', 'quantity', 'amount', 'action']"
      :columnProps="{
        pricePerUnit: { class: 'large-cell' },
        quantity: { class: 'large-cell' },
        amount: { class: 'price-cell' },
        action: { class: 'small-cell' },
      }"
    >
      <template #cell-name="{ row }">
        <div class="cell-name-container">
          <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
            <div class="product-info" :title="row.name">
              <span>{{ row.name }}</span>
              <span class="product-info-secondary">
                {{ row.sku }} &#8226; {{ row.category | categoryWithParent }}
              </span>
            </div>
          </ez-entity-info>
        </div>
      </template>
      <template #cell-pricePerUnit="{ row }">
        <div class="changeable-price">
          <div class="input-warning">
            <ez-mask-input
              :key="row.id"
              :value="row.price || 0"
              @input="onPriceChange(row, $event)"
            />
            <font-awesome-icon
              v-tooltip="{
                content: `Setting price manually will override current price
                and any existing quantity discount tiers for this order.`,
                classes: ['tooltip-general'],
              }"
              icon="info-circle"
              class="input-warning__icon"
            />
          </div>
        </div>
      </template>
      <template #cell-quantity="{ row: { id, quantity, outOfStock } }">
        <ez-mask-input-simple
          :class="[{ 'out-of-stock': outOfStock }]"
          name="quantity"
          formKey=""
          :minValue="0"
          :value="quantity"
          @input="onQuantityChange($event, id)"
          :has-currency="false"
        />
      </template>
      <template #cell-amount="{ row }">
        {{ (row.price * row.quantity) | price }}
      </template>
      <template #cell-action="{ row }">
        <button
          class="remove-item"
          v-tooltip="{
            content: `${row.isNew ? 'This product will be deleted permanently' : ''}`,
            classes: ['tooltip-general'],
            placement: 'start',
          }"
          @click="removeItem(row)"
        >
          <font-awesome-icon icon="times" />
        </button>
      </template>
    </ez-table>
    <template v-if="products.length">
      <div class="total-amount-tax">
        <v-subtotal-info :item-list="itemList" :delivery-fee="deliveryFee" :tax="tax">
          Total (incl. taxes)
        </v-subtotal-info>
      </div>
    </template>
    <empty-state v-if="!products.length" class="empty-state">
      <template #badge><div></div></template>
      <template #title>No products added yet</template>
      <template #info>All the products you add will be listed here.</template>
    </empty-state>
    <ez-csv-upload-modal
      :venue-id="venueId"
      :is-pending-invoice="true"
      ref="csvUploadModal"
      :uploadCSV="uploadAction"
      :onUploadFinished="onUploadFinished"
    />
  </div>
</template>
<style lang="scss">
.products {
  display: flex;

  .product-info {
    display: flex;
    flex-direction: column;
  }

  .cell-product-container {
    @extend %flex-center;
    justify-content: flex-start;
  }

  .search-box__title {
    @include font-size(16px, 22px);
    margin: 0;
  }

  .search-box__header {
    @extend %flex-center;
    justify-content: space-between;
    margin-bottom: 22px;
  }

  .search-box__body {
    height: 580px;
    overflow: auto;
  }

  .search-box__actions {
    @extend %flex-space;
    height: 56px;
  }

  .search-box__msg {
    @extend %flex-center;
    @include font-size(14px, 22px);
    color: $color-gray-6C;
  }

  &__box {
    flex: 1 1 100%;
    border: 1px #ccd2e0;
    border-radius: 3px;
    max-width: 576px;
    height: 708px;

    &:first-child {
      margin-right: 48px;
      border-style: solid;
    }
    &:last-child {
      border-style: dashed;
      padding: 12px;
    }
    .image-zoom {
      width: 100%;
      height: 100%;
    }

    .venue-result {
      @extend %flex-center;

      &__image {
        @include size(32px);
        border-radius: 50%;
        border: 1px solid #dee1e4;
      }

      &__info {
        margin-left: 8px;
      }

      &__titele {
        color: #252631;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }

      &__label {
        color: #6c7995;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
      }
    }

    .empty-state-suppliers {
      margin-top: 110px;
    }

    .result-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .entity-info {
      margin-left: 8px;
    }
    .price {
      @include font-size(16px);
      font-weight: 600;
      color: $color-gray-25;
      padding-right: 12px;
    }
    .actions {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
  }
  .create-new-supplier {
    display: flex;
    align-items: center;
    .badge {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      @extend %flex-center;
      justify-content: center;
      background-color: #e2eaff;
      color: #4d7cfe;
      @include font-size(20px);
    }
  }
  .new-supplier {
    border-style: solid;
    padding: 0;
  }
  .supplier-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .title,
    .footer {
      width: 100%;
      padding: 0 24px;
      display: flex;
      align-items: center;
    }
    .title {
      height: 72px;
      justify-content: space-between;
      @include font-size(18px, 24px);
      font-weight: 500;

      .remove-item {
        @extend %button-reset;
        outline: none;
        svg {
          color: #c5cad5;
        }
        :hover {
          cursor: pointer;
        }
      }
    }
    .footer {
      height: 68px;
      justify-content: flex-end;
    }
    .content {
      max-height: 568px;
      overflow: auto;
      padding: 0 24px;
    }
  }
}
.remove-item {
  @extend %button-reset;
  @include size(16px);
  line-height: 0;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    fill: #6c7995;
  }
}
.empty-state {
  margin-top: 64px;
}
.products-table {
  margin-top: 64px;

  .product-info {
    display: flex;
    flex-direction: column;
  }
}
.input-warning {
  display: flex;
  align-items: center;
  &__icon {
    margin-left: 6px;
  }
}
.new-product {
  :deep() .ez-select {
    width: 100%;
  }
  :deep() .ez-image-upload {
    .entity-info {
      &__preview {
        margin: 0 auto;
      }
    }
  }
}
.total-amount-tax {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  @include font-size(14px);
  color: $color-gray-6C;
  text-transform: uppercase;
  font-weight: 500;
  span {
    @include font-size(16px);
    color: $color-gray-25;
    margin-left: 12px;
  }
}
</style>
