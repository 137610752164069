<template>
  <ez-user-list
    role="distributor"
    :users="users"
    :columns="['name', 'email', 'role']"
    :columnProps="{
      role: { class: 'cell-role' },
    }"
    @userClick="onUserClick"
    @addUser="onAddUser"
    @removeUser="onRemoveUser"
  >
    <ez-distributor-user-modal
      ref="userModal"
      :roles="roles"
      :distributorId="draft.id"
      :userId="selectedUserId"
      @submitSuccess="onSubmitSuccess"
    />
  </ez-user-list>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzUserList, { EzDistributorUserModal } from '@/views/common/users';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzUserList,
    EzDistributorUserModal,
  },
  data() {
    return {
      showPermissions: false,
      selectedUserId: null,
      roles: [],
      users: [],
    };
  },
  computed: {
    ...mapState('entities/distributors', ['draft']),
  },
  methods: {
    ...mapActions('entities/users', [
      'removeAdminDistributorUser',
      'fetchAdminDistributorUsersNew',
    ]),
    ...mapActions('roles', ['getAdminDistributorRoles']),
    ...mapMutations('entities/distributors', ['UPDATE_DRAFT']),
    async fetchUsers() {
      const { data } = await this.fetchAdminDistributorUsersNew({ distributorId: this.draft.id });
      this.users = data.data;
    },
    onNextStep() {
      this.UPDATE_DRAFT({ users: this.users });
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    openModal() {
      this.$refs.userModal.open();
    },
    async onUserClick({ id }) {
      this.selectedUserId = id;
      this.openModal();
    },
    async onAddUser() {
      this.selectedUserId = -1;
      this.openModal();
    },
    onRemoveUser({ id }, replacementOwnerId) {
      this.removeAdminDistributorUser({
        distributorId: this.draft.id,
        id,
        replacementOwnerId,
      });
      this.users = this.users.filter(usr => usr.id !== id);
    },
    onRoleChange(role) {
      this.showPermissions = role.id === 'manager';
    },
    onSubmitSuccess({ data }) {
      const { id } = data;
      const idx = this.users.findIndex(u => u.id === id);

      if (idx < 0) {
        this.users.push(data);
        return;
      }

      this.users.splice(idx, 1, data);
    },
  },
  async mounted() {
    [this.roles] = await Promise.all([this.getAdminDistributorRoles(), this.fetchUsers()]);
  },
  watch: {
    users(val) {
      if (val.length) this.enableNextStep();
      else this.disableNextStep();
    },
  },
};
</script>

<style scoped lang="scss">
:deep() .table {
  .cell-role {
    text-align: right;
  }
}

.ez-distributor-manager-permissions {
  @include separator();
}
</style>
