<template>
  <div>
    <paper>
      <template #actions>
        <ez-button type="link" formType="button" @click="editGroup">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <ez-entity-info imgWidth="4rem" imgHeight="4rem" imgBorderRadius="50%" :imgUrl="draft.logo">
        {{ draft.name }}
      </ez-entity-info>
    </paper>
    <paper class="venues-overview">
      <template #title>{{ $t('global.venues') }}</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editVenues">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-for="venue in venues" :key="venue.id" class="info info--horizontal">
        <span>{{ `${draft.name} - ${venue.name}` }}</span>
      </div>
    </paper>
    <paper>
      <template #title>Users</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editUsers">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-for="user in users" :key="user.id" class="info info--horizontal">
        <span>{{ user.name }}</span>
        <small>{{ user.role ? user.role.name : '' }}</small>
      </div>
    </paper>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import EzButton from '@/components/ui/Button';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { VENUE_STEP_1, VENUE_STEP_2, VENUE_STEP_3 } from './steps';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzButton,
    EzEntityInfo,
    Paper,
  },
  data() {
    return {
      venues: [],
      users: [],
    };
  },
  computed: {
    ...mapState('entities/groups', ['draft']),
  },
  methods: {
    ...mapActions('entities/users', ['adminFetchUsers']),
    ...mapActions('entities/venues', ['fetchVenuesForGroupAdmin']),
    ...mapMutations('entities/groups', ['CLEAR_DRAFT']),
    async fetchVenuesForGroup() {
      const { data } = await this.fetchVenuesForGroupAdmin({ groupId: this.draft.id });
      this.venues = data.data;
    },
    async fetchUsers() {
      const { data } = await this.adminFetchUsers({ groupId: this.draft.id });
      this.users = data.data;
    },
    onNextStep() {
      this.CLEAR_DRAFT();
      localStorage.removeItem('venueGroupDraft');
      this.$router.push({
        name: 'admin-venue-groups',
        params: {
          flash: {
            title: `${this.$t('global.venue')} group created!`,
            type: 'success',
          },
        },
      });
    },
    editGroup() {
      this.$emit('stepBack', VENUE_STEP_1);
    },
    editVenues() {
      this.$emit('stepBack', VENUE_STEP_2);
    },
    editUsers() {
      this.$emit('stepBack', VENUE_STEP_3);
    },
  },
  async mounted() {
    if (!this.draft || !this.draft.id) {
      return;
    }

    this.enableNextStep();
    await Promise.all([this.fetchVenuesForGroup(), this.fetchUsers()]);
  },
};
</script>

<style scoped lang="scss">
:deep() .entity-info {
  flex-direction: column;
  align-items: center;
  &__text {
    margin: 0.75rem 0 0;
    justify-content: center;
  }
}
</style>
