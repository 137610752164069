<script>
/**
 *
 * @version 1.0.0
 * @since 3.0.2
 */
import { wizardListenerMixin } from '@/mixins/wizard';
import EzLoader from '@/components/ui/Loader';
import EzButton from '@/components/ui/Button';
import Paper from '@/components/layout/WizardOverviewPaper';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { mapActions, mapState, mapMutations } from 'vuex';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo/index';
import dayjs from 'dayjs';
import flash from '@/components/ui/FlashMessage';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import { calculateDeliveryFee } from '@/util/deliveryFee';
import {
  addTotalPriceProp,
  getTotalAmountQuantityPrice,
  getTotalWithTax,
} from '@/util/utilsFinCalculator';
import { formatDate } from '@/util/utils';
import { OFFLINE_ORDER_STEP_1, OFFLINE_ORDER_STEP_2 } from './steps';

export default {
  mixins: [wizardListenerMixin],
  components: {
    VSubtotalInfo,
    EzButton,
    Paper,
    EzEntityInfo,
    EzLoader,
    VProductEntityInfo,
  },
  data() {
    return {
      loading: false,
      distributor: {},
      totalAmount: 0,
    };
  },
  computed: {
    ...mapState('entities/venues', ['offlineOrderNewSupplier']),
    ...mapState('entities/users', ['contextId']),
    products() {
      return this.offlineOrderNewSupplier.products;
    },

    date() {
      return formatDate(this.offlineOrderNewSupplier.date);
    },
    distributorId() {
      return this.offlineOrderNewSupplier.supplier.id;
    },
    invoices() {
      return this.offlineOrderNewSupplier.pendingUpdate.invoices;
    },
    venueId() {
      return this.$route.params.venueId;
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return addTotalPriceProp({ list: this.products });
    },
    deliveryFee() {
      return calculateDeliveryFee(this.distributor, this.totalAmount);
    },
    tax() {
      return this.distributor?.tax || 0;
    },
    // Props for VSubtotalInfo - End
    total() {
      return getTotalWithTax({
        itemList: this.itemList,
        deliveryFee: this.deliveryFee,
        tax: this.tax,
      });
    },
  },
  methods: {
    ...mapActions('entities/venues', ['adminCreateOfflineOrder']),
    ...mapActions('entities/distributors', ['adminFetchSupplierSingle']),
    editDistributor() {
      this.$emit('stepBack', OFFLINE_ORDER_STEP_1);
    },
    editProducts() {
      this.$emit('stepBack', OFFLINE_ORDER_STEP_2);
    },
    async getSingleDistributor() {
      const { data } = await this.adminFetchSupplierSingle({
        distributorId: this.distributorId,
      });
      this.distributor = data.data;
    },
    async onNextStep() {
      const offlineOrder = {
        orders: [
          {
            distributorId: this.distributorId,
            products: this.products.map(pr => ({
              id: pr.id,
              quantity: pr.quantity,
              price: pr.price,
            })),
            invoiceId: this.offlineOrderNewSupplier.pendingUpdate.id,
          },
        ],
        type: 'offline',
        orderedAt: dayjs(this.offlineOrderNewSupplier.date).toISOString(),
        venueId: this.venueId,
      };
      try {
        await this.adminCreateOfflineOrder({
          data: offlineOrder,
        });
        this.CLEAR_OFFLINE_ORDER_NEW_SUPPLIER();
        await this.$router.push({
          name: 'admin-venue-pending-updates',
          params: {
            flash: {
              title: 'Offline order created successfully!',
              message: 'You can now see this order in your order history.',
              type: 'success',
            },
          },
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    ...mapMutations('entities/venues', ['CLEAR_OFFLINE_ORDER_NEW_SUPPLIER']),
  },
  mounted() {
    this.getSingleDistributor();
    this.totalAmount = getTotalAmountQuantityPrice(this.products);
    this.enableNextStep();
  },
};
</script>

<template>
  <div>
    <paper>
      <template #actions>
        <ez-button type="link" formType="button" @click="editDistributor">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <ez-entity-info
        class="venue-info"
        imgWidth="64px"
        imgHeight="64px"
        imgBorderRadius="50%"
        :img-url="offlineOrderNewSupplier.supplier.logo">
        {{ offlineOrderNewSupplier.supplier.name }}
      </ez-entity-info>
      <hr>
      <div class="info info--vertical">
        <small>Order Date</small>
        <span>{{ date }}</span>
      </div>
    </paper>
    <paper v-if="true">
      <template #actions>
        <ez-button type="link" formType="button" @click="editDistributor">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <template #title>Main info</template>
      <div class="info info--vertical">
        <small>Primary Contact Name</small>
        <span>{{ distributor.primaryContactName }}</span>
      </div>
      <div class="info info--vertical">
        <small>Primary Contact Phone Number</small>
        <span>{{ distributor.primaryContactPhone }}</span>
      </div>
      <hr>
      <div v-if="distributor.address" class="address">
        <div v-if="distributor.address.street" class="info info--vertical">
          <small>Street Address</small>
          <span>{{ distributor.address.street }}</span>
        </div>
        <div v-if="distributor.address.city" class="info info--vertical">
          <small>City</small>
          <span>{{ distributor.address.city }}</span>
        </div>
        <div v-if="distributor.address.zipCode" class="info info--vertical">
          <small>Zip code</small>
          <span>{{ distributor.address.zipCode }}</span>
        </div>
        <div v-if="distributor.address.country" class="info info--vertical">
          <small>Country</small>
          <span>{{ distributor.address.country }}</span>
        </div>
      </div>
    </paper>
    <paper>
      <template #title>Products</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editProducts">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <table class="product-table">
        <tbody>
          <tr
            v-for="product in offlineOrderNewSupplier.products"
            :key="product.id">
            <td class="item">
              <v-product-entity-info
                class="entity-info"
                :product="product" />
            </td>
            <td class="product-table__quantity">{{ product.quantity }}</td>
            <td class="product-table__price">{{ product.price | price }}</td>
          </tr>
        </tbody>
      </table>
      <hr class="full">
      <div class="info info--horizontal">
        <div class="overview-paper__title">
          <h4>
            Total <template v-if="tax">(Incl. Taxes)</template>
            <v-subtotal-info
              class="ml-4"
              :item-list="itemList"
              :delivery-fee="deliveryFee"
              :tax="tax"
              :show-total="false"
            />
          </h4>
        </div>
        <span class="total-price">{{ total | price }}</span>
      </div>
    </paper>
    <paper>
      <template #title>Invoices ({{ invoices.length }})</template>
      <div class="invoice">
        <div v-for="invoice in invoices" :key="invoice.name" class="invoice__item">
          <img :src="invoice.image" alt="">
          {{ invoice.name }}
        </div>
      </div>
    </paper>
    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
.venue-info {
  flex-direction: column;
  align-items: center;
  :deep() .entity-info__text {
    margin: 0.75rem 0 0;
    justify-content: center;
    font-weight: 500;
  }
}
.product-table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  .item {
    width: 65%;
  }
  &__price {
    text-align: right;
    font-weight: 500;
    @include font-size(14px);
    color: $color-gray-25;
    width: 20%;
  }
  &__quantity {
    width: 15%;
    font-weight: 500;
    @include font-size(14px);
    color: $color-gray-6C;
    text-align: right;
  }
}
.total-price {
  @include font-size(16px);
  font-weight: 500;
  color: $color-gray-25;
}
.invoice {
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include font-size(14px);
    color: $color-gray-25;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    img {
      width: 52px;
      max-height: 48px;
      margin-right: 8px;
      object-fit: contain;
    }
  }
}
</style>
