<template>
  <order-list
    :columns="[
      'orderNumber',
      'statusIcon',
      'venue',
      'distributor',
      'orderedAt',
      'status',
      'amount',
    ]"
    :headers="{
      orderNumber: () => 'Order No.',
      statusIcon: () => ``,
      distributor: () => $t('global.distributor').toLowerCase(),
      venue: () => $t('global.venue').toLowerCase(),
      orderedAt: () => 'Order Date',
    }"
    :filters="filters"
    :grouped-orders="groupedOrders"
    @loadMore="onLoadMoreClick"
    @resetFilters="resetFilters"
    @updateFilters="updateFilters"
    @rowClick="loadOrderDrawer"
  >
    <template #title> Order History </template>
    <template #filters>
      <v-date-picker
        class="mr-16 calendar"
        name="month"
        label="Range"
        v-model="range"
        formKey="filters"
        range-mode
        hide-label
        with-predefined-ranges
        :numberOfCalendars="2"
        selectPredefinedRange="All Time"
      />
      <v-select-search
        class="mr-16"
        formKey="filters"
        name="venueId"
        search="/admin/venues"
        :searchLimit="30"
        :data="venues"
        :placeholder="`All ${$t('global.venues')}`"
        :searchPlaceholder="`Select ${$t('global.venue')}`"
        :selected="filters.venueId ? filters.venueId : ''"
        :min-length="3"
      >
      <template #result="{ result }">
        <ez-entity-info
          :imgUrl="result.logo"
          imgWidth="2rem"
          imgHeight="2rem"
          imgBorderRadius="50%"
        >
          <span class="entity-info__name">{{ result.name }}</span>
        </ez-entity-info>
      </template>
      </v-select-search>
      <v-select-search
        class="mr-16"
        formKey="filter-orders"
        name="distributorId"
        search="/admin/distributors"
        :searchLimit="30"
        :data="distributors"
        :placeholder="`All ${$t('global.distributors')}`"
        :searchPlaceholder="`Select ${$t('global.distributor')}`"
        :selected="filters.distributorId ? filters.distributorId : ''"
        :min-length="3"
      >
        <template #result="{ result }">
          <ez-entity-info
            :imgUrl="result.logo"
            imgWidth="2rem"
            imgHeight="2rem"
            imgBorderRadius="50%"
          >
            <span class="entity-info__name">{{ result.name }}</span>
          </ez-entity-info>
        </template>
      </v-select-search>
      <ez-dropdown name="status" formKey="filter-orders" :data="statusFilter" />
    </template>
    <template #actions>
      <export-orders action="admin/orders" :orders="orders" :filters="filters" />
    </template>
    <single-order ref="singleOrder" :order="order" />
  </order-list>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { groupListByDateFormat } from '@/util/utils';
import EzDropdown from '@/components/ui/Dropdown';
import {
  LOADING_KEY,
  ORDER_STATUS_NEEDS_APPROVAL,
  statusFilterLabelsSupplier,
} from '@/util/constants';
import ExportOrders from '@/views/common/orders/ExportOrders';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import dayjs from 'dayjs';
import OrderList from './OrderList.vue';
import SingleOrder from './SingleOrder.vue';

export default {
  components: {
    VSelectSearch,
    EzDropdown,
    SingleOrder,
    OrderList,
    VDatePicker,
    ExportOrders,
    EzEntityInfo,
  },
  props: {
    orderId: {
      type: Number,
    },
  },
  data() {
    return {
      routeOrderSingle: 'admin-order-history',
      filters: {
        venueId: null,
        distributorId: null,
        status: null,
        to: null,
        from: null,
      },
      range: {
        start: dayjs().subtract(7, 'd'),
        end: new Date(),
      },
      venues: [],
      distributors: [],
      order: {},
      orders: [],
      meta: {},
      groupedOrders: {},
    };
  },
  computed: {
    dateFilter() {
      return [
        {
          id: null,
          from: null,
          to: null,
          name: 'All Time',
        },
        ...this.$helpers.generateMonthsToDate(this.meta.firstOrderedAt),
      ];
    },
    statusFilter() {
      const statuses = Object.entries(statusFilterLabelsSupplier)
        .filter(([status]) => status !== ORDER_STATUS_NEEDS_APPROVAL)
        .map(([status, label]) => ({ id: status, name: label }));

      return [
        {
          id: null,
          name: 'All Statuses',
        },
        ...statuses,
      ];
    },
  },
  methods: {
    ...mapActions('entities/orders', [
      'adminFetchOrderHistory',
      'adminFetchSingleOrder',
    ]),
    ...mapActions('entities/venues', ['fetchAdminVenues']),
    ...mapActions('entities/distributors', ['fetchAdminDistributors']),
    ...mapMutations('entities/orders', ['UPDATE_META']),
    resetData() {
      this.orders = [];
      this.meta = {};
    },
    resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });

      this.resetData();
      this.refresh();
    },
    updateFilters(filterName, event) {
      if (filterName === 'month') {
        this.filters = {
          ...this.filters,
          from: dayjs(this.range.start).valueOf(),
          to: dayjs(this.range.end).valueOf(),
        };
      } else {
        this.filters = { ...this.filters, [filterName]: event.id };
      }

      this.resetData();
      this.refresh(this.filters);
    },
    async loadOrderDrawer({ id }) {
      try {
        const {
          data: { data },
        } = await this.adminFetchSingleOrder({ orderId: id });
        this.order = data;
        if (id !== this.orderId) {
          await this.$router.replace({
            name: this.routeOrderSingle,
            params: { id },
          });
        }
        this.$refs.singleOrder.openDrawer();
      } catch (e) {
        await this.$router.replace({ name: 'distributor-home' });
        console.error(e);
      }
    },
    onLoadMoreClick() {
      this.refresh({
        ...this.filters,
        loadingKey: LOADING_KEY.FETCH_ADMIN_ORDER_HISTORY_LOAD_MORE,
      });
    },
    async refresh(query = {}) {
      const combinedQuery = {
        ...query,
        ...this.meta,
      };

      try {
        const {
          data: { data, meta },
        } = await this.adminFetchOrderHistory({ ...combinedQuery });
        this.orders = [...this.orders, ...data];
        this.meta = meta;
        this.UPDATE_META({ meta: this.meta });

        this.groupedOrders = groupListByDateFormat({
          list: this.orders,
          dateKey: 'expectedAt',
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  async mounted() {
    const [
      { data: { data: distributors } },
      { data: { data: outlets } },
    ] = await Promise.all([
      this.fetchAdminDistributors({ query: { limit: 30 } }),
      this.fetchAdminVenues({ query: { limit: 30 } }),
    ]);
    this.venues = outlets;
    this.distributors = distributors;
    await this.refresh();
    if (this.orderId) {
      await this.loadOrderDrawer({ id: this.orderId });
    }
  },
};
</script>

<style scoped lang="scss">
:deep() .order-list {
  &__header-wrapper {
    padding: 1.5rem 3rem 0 3rem;
  }
  &__content-wrapper {
    padding: 0 3rem 1.5rem;
  }
}
.calendar {
  :deep() .date-picker {
    width: auto;

    .placeholder {
      font-size: 14px;
      color: $color-gray-6C;
    }
  }
}

:deep() .select-search {
  color: $color-gray-6C;

  button {
    @include font-size(14px);
  }

  &__list {
    max-height: 244px;
  }
}
</style>
